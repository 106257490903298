import axios from "axios";
import { API_URL } from "../constants";
import {
  getSessionItem,
  setSessionItem,
  removeSessionItem,
  _dispatch,
} from "../utilities";
import { ToastsStore } from "react-toasts";
export { _dispatch };
export const SAVE_AUTH = "SAVE_AUTH";
export const TOTAL_SESSION = "TOTAL_SESSION";
export const TOTAL_STATS = "TOTAL_STATS";

const TEMP_API = "http://192.168.1.20:3002";

export function registerUser(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/signup`,
  });
}

export function verifyRegistraionOTP(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/verify-email`,
  });
}

export function activateAccount(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/activate-account`,
  });
}

export function loginUser(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/login`,
  });
}

export function fetchStagesStaticContent(authResponse) {
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${authResponse.data.body.token}` },
    url: `${API_URL}/auxiliary/stages`,
  });
}

export function saveLoginInfo(response, stages, firstTime = 0) {
  setSessionItem("user", {
    ...response.data.body,
    session: response.data.body.recentSession,
    stages,
    firstTime,
  });
  return {
    type: SAVE_AUTH,
    payload: true,
    session: response.data.body.recentSession
      ? response.data.body.recentSession
      : { stage: 1 },
  };
}

export function verifySignupPayment(params) {
  return axios({
    method: "POST",
    data: params,
    url: "https://ipn.der-beste-coach.de/index.php?action=pay",
  });
}

export function fetchModerationVideo(params) {
  return axios({
    method: "GET",
    params: params,
    url: `${API_URL}/user/customer/moderationVideo`,
  });
}

export function getFaqs(params) {
  return axios({
    method: "GET",
    params: params,
    url: `${API_URL}/user/customer/list-faqs`,
  });
}

export function changePassword(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/change-password`,
  });
}

export function getStaticPages(params) {
  return axios({
    method: "GET",
    params: params,
    url: `${API_URL}/user/customer/static-page-details`,
  });
}

export function getSettings(params) {
  return axios({
    method: "GET",
    params: params,
    url: `${API_URL}/user/customer/settings`,
  });
}

export function userSessionCompleteVideo(params) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: params,
    url: `${API_URL}/user/session-completed-count`,
  });
}

export function totalSession(payload) {
  _dispatch({
    type: TOTAL_SESSION,
    payload: payload,
  });
}

export function forgotPassword(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/forgot-password`,
  });
}

export function inviteViaEmail(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: `${API_URL}/user/customer/email-invitation`,
  });
}

export function getReferralStats(params) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: params,
    url: `${API_URL}/user/referral-stats`,
  });
}

export function totalStats(payload) {
  _dispatch({
    type: TOTAL_STATS,
    payload: payload,
  });
}

export function referralLogs(pageNumber, limit, params = {}) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: { ...params, limit, skip: limit * (pageNumber - 1) },
    url: `${API_URL}/user/referral-logs`,
  });
}

export function userPayoutList(pageNumber, limit, params = {}) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: { ...params, limit, skip: limit * (pageNumber - 1) },
    url: `${API_URL}/user/payout-list`,
  });
}

export function createPayout(payload) {
  const user = getSessionItem("user");
  return axios({
    method: "POST",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    data: payload,
    url: `${API_URL}/user/create-payout`,
  });
}

export function getUserDetail(params) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: params,
    url: `${API_URL}/user/my-data`,
  });
}

export function userTransactions(params) {
  const user = getSessionItem("user");
  return axios({
    method: "GET",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    params: params,
    url: `${API_URL}/user/admin/list-user-transactions`,
  });
}

export function connectStripeAccount(payload) {
  const user = getSessionItem("user");
  return axios({
    method: "POST",
    headers: { Authorization: `Bearer ${user ? user.token : ""}` },
    data: payload,
    url: `${API_URL}/payment/stripe-connect-link`,
  });
}

// export function referCodeSuccess(payload){
//       _dispatch({
//             type: REFER_CODE_SUCCESS,
//             payload: payload
//       });
// }
