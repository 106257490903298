// Development
export const API_BASE = "https://api.der-beste-coach.de";
export const WEBSITE_URL = "https://dev.der-beste-coach.de";
// export const WEBSITE_URL = "http://localhost:3002";

// export const ROOT = 'localhost:3001';
export const ROOT = "dev.der-beste-coach.de";
export const API_URL = API_BASE;
export const MEDIA_BASE = API_BASE + "/pub/media";
export const FBPIXELID = "";
export const GUEST_RECORDING_LIMIT = 2;

export const SESSION_RATING = {
  1: "ratesmile",
  2: "norate",
  3: "ratesad",
};

export const SYSTEM = {
  CURRENCY: "€",
  CURRENCYCODE: "EUR",
  STRIPEKEY:
    "pk_test_51LEtITGcMG6WxZ3C5Wu4NcTE3MGhX0btsQ47Hrj5Nhdu2jmXFJqhQvg4I6WzgBNE85PvA2fjgeEyFxF42UDgddFf00y4eRniQn",
  // STRIPEKEY: 'pk_test_U1TW269wLK37ca7bK3kG5CkO00EGvj5Bu4'
  // STRIPEKEY: 'pk_test_51ITJnEGl6Wy7ySIDHOogk0JVb3V4bdg9HzqgAokQW3Nuk766w0lA4a3k4aTrRlysek7AT7YnYvKOySBE16gValbc007jaNmGKI'
};

//Germany: Deutschland, austria: Österreich, switzerland: Schweiz
export const COUNTRY_CODE = [
  {
    id: 82,
    sortname: "DE",
    country: "Deutschland",
    status: 0,
    phonecode: 49,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 14,
    sortname: "AT",
    country: "Österreich",
    status: 0,
    phonecode: 43,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 212,
    sortname: "CH",
    country: "Schweiz",
    status: 0,
    phonecode: 41,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 1,
    sortname: "AF",
    country: "Afghanistan",
    status: 0,
    phonecode: 93,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 2,
    sortname: "AL",
    country: "Albania",
    status: 0,
    phonecode: 355,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 3,
    sortname: "DZ",
    country: "Algeria",
    status: 0,
    phonecode: 213,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 4,
    sortname: "AS",
    country: "American Samoa",
    status: 0,
    phonecode: 1684,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 5,
    sortname: "AD",
    country: "Andorra",
    status: 0,
    phonecode: 376,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 6,
    sortname: "AO",
    country: "Angola",
    status: 0,
    phonecode: 244,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 7,
    sortname: "AI",
    country: "Anguilla",
    status: 0,
    phonecode: 1264,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 8,
    sortname: "AQ",
    country: "Antarctica",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 9,
    sortname: "AG",
    country: "Antigua And Barbuda",
    status: 0,
    phonecode: 1268,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 10,
    sortname: "AR",
    country: "Argentina",
    status: 0,
    phonecode: 54,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 11,
    sortname: "AM",
    country: "Armenia",
    status: 0,
    phonecode: 374,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 12,
    sortname: "AW",
    country: "Aruba",
    status: 0,
    phonecode: 297,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 13,
    sortname: "AU",
    country: "Australia",
    status: 0,
    phonecode: 61,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },

  {
    id: 15,
    sortname: "AZ",
    country: "Azerbaijan",
    status: 0,
    phonecode: 994,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 16,
    sortname: "BS",
    country: "Bahamas The",
    status: 0,
    phonecode: 1242,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 17,
    sortname: "BH",
    country: "Bahrain",
    status: 0,
    phonecode: 973,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 18,
    sortname: "BD",
    country: "Bangladesh",
    status: 0,
    phonecode: 880,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 19,
    sortname: "BB",
    country: "Barbados",
    status: 0,
    phonecode: 1246,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 20,
    sortname: "BY",
    country: "Belarus",
    status: 0,
    phonecode: 375,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 21,
    sortname: "BE",
    country: "Belgium",
    status: 0,
    phonecode: 32,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 22,
    sortname: "BZ",
    country: "Belize",
    status: 0,
    phonecode: 501,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 23,
    sortname: "BJ",
    country: "Benin",
    status: 0,
    phonecode: 229,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 24,
    sortname: "BM",
    country: "Bermuda",
    status: 0,
    phonecode: 1441,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 25,
    sortname: "BT",
    country: "Bhutan",
    status: 0,
    phonecode: 975,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 26,
    sortname: "BO",
    country: "Bolivia",
    status: 0,
    phonecode: 591,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 27,
    sortname: "BA",
    country: "Bosnia and Herzegovina",
    status: 0,
    phonecode: 387,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 28,
    sortname: "BW",
    country: "Botswana",
    status: 0,
    phonecode: 267,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 29,
    sortname: "BV",
    country: "Bouvet Island",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 30,
    sortname: "BR",
    country: "Brazil",
    status: 0,
    phonecode: 55,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 31,
    sortname: "IO",
    country: "British Indian Ocean Territory",
    status: 0,
    phonecode: 246,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 32,
    sortname: "BN",
    country: "Brunei",
    status: 0,
    phonecode: 673,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 33,
    sortname: "BG",
    country: "Bulgaria",
    status: 0,
    phonecode: 359,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 34,
    sortname: "BF",
    country: "Burkina Faso",
    status: 0,
    phonecode: 226,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 35,
    sortname: "BI",
    country: "Burundi",
    status: 0,
    phonecode: 257,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 36,
    sortname: "KH",
    country: "Cambodia",
    status: 0,
    phonecode: 855,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 37,
    sortname: "CM",
    country: "Cameroon",
    status: 0,
    phonecode: 237,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 38,
    sortname: "CA",
    country: "Canada",
    status: 0,
    phonecode: 1,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 39,
    sortname: "CV",
    country: "Cape Verde",
    status: 0,
    phonecode: 238,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 40,
    sortname: "KY",
    country: "Cayman Islands",
    status: 0,
    phonecode: 1345,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 41,
    sortname: "CF",
    country: "Central African Republic",
    status: 0,
    phonecode: 236,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 42,
    sortname: "TD",
    country: "Chad",
    status: 0,
    phonecode: 235,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 43,
    sortname: "CL",
    country: "Chile",
    status: 0,
    phonecode: 56,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 44,
    sortname: "CN",
    country: "China",
    status: 0,
    phonecode: 86,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 45,
    sortname: "CX",
    country: "Christmas Island",
    status: 0,
    phonecode: 61,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 46,
    sortname: "CC",
    country: "Cocos (Keeling) Islands",
    status: 0,
    phonecode: 672,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 47,
    sortname: "CO",
    country: "Colombia",
    status: 0,
    phonecode: 57,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 48,
    sortname: "KM",
    country: "Comoros",
    status: 0,
    phonecode: 269,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 49,
    sortname: "CG",
    country: "Republic Of The Congo",
    status: 0,
    phonecode: 242,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 50,
    sortname: "CD",
    country: "Democratic Republic Of The Congo",
    status: 0,
    phonecode: 242,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 51,
    sortname: "CK",
    country: "Cook Islands",
    status: 0,
    phonecode: 682,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 52,
    sortname: "CR",
    country: "Costa Rica",
    status: 0,
    phonecode: 506,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 53,
    sortname: "CI",
    country: "Cote D'Ivoire (Ivory Coast)",
    status: 0,
    phonecode: 225,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 54,
    sortname: "HR",
    country: "Croatia (Hrvatska)",
    status: 0,
    phonecode: 385,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 55,
    sortname: "CU",
    country: "Cuba",
    status: 0,
    phonecode: 53,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 56,
    sortname: "CY",
    country: "Cyprus",
    status: 0,
    phonecode: 357,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 57,
    sortname: "CZ",
    country: "Czech Republic",
    status: 0,
    phonecode: 420,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 58,
    sortname: "DK",
    country: "Denmark",
    status: 0,
    phonecode: 45,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 59,
    sortname: "DJ",
    country: "Djibouti",
    status: 0,
    phonecode: 253,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 60,
    sortname: "DM",
    country: "Dominica",
    status: 0,
    phonecode: 1767,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 61,
    sortname: "DO",
    country: "Dominican Republic",
    status: 0,
    phonecode: 1809,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 62,
    sortname: "TP",
    country: "East Timor",
    status: 0,
    phonecode: 670,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 63,
    sortname: "EC",
    country: "Ecuador",
    status: 0,
    phonecode: 593,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 64,
    sortname: "EG",
    country: "Egypt",
    status: 0,
    phonecode: 20,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 65,
    sortname: "SV",
    country: "El Salvador",
    status: 0,
    phonecode: 503,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 66,
    sortname: "GQ",
    country: "Equatorial Guinea",
    status: 0,
    phonecode: 240,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 67,
    sortname: "ER",
    country: "Eritrea",
    status: 0,
    phonecode: 291,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 68,
    sortname: "EE",
    country: "Estonia",
    status: 0,
    phonecode: 372,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 69,
    sortname: "ET",
    country: "Ethiopia",
    status: 0,
    phonecode: 251,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 70,
    sortname: "XA",
    country: "External Territories of Australia",
    status: 0,
    phonecode: 61,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 71,
    sortname: "FK",
    country: "Falkland Islands",
    status: 0,
    phonecode: 500,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 72,
    sortname: "FO",
    country: "Faroe Islands",
    status: 0,
    phonecode: 298,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 73,
    sortname: "FJ",
    country: "Fiji Islands",
    status: 0,
    phonecode: 679,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 74,
    sortname: "FI",
    country: "Finland",
    status: 0,
    phonecode: 358,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 75,
    sortname: "FR",
    country: "France",
    status: 0,
    phonecode: 33,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 76,
    sortname: "GF",
    country: "French Guiana",
    status: 0,
    phonecode: 594,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 77,
    sortname: "PF",
    country: "French Polynesia",
    status: 0,
    phonecode: 689,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 78,
    sortname: "TF",
    country: "French Southern Territories",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 79,
    sortname: "GA",
    country: "Gabon",
    status: 0,
    phonecode: 241,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 80,
    sortname: "GM",
    country: "Gambia The",
    status: 0,
    phonecode: 220,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 81,
    sortname: "GE",
    country: "Georgia",
    status: 0,
    phonecode: 995,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 83,
    sortname: "GH",
    country: "Ghana",
    status: 0,
    phonecode: 233,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 84,
    sortname: "GI",
    country: "Gibraltar",
    status: 0,
    phonecode: 350,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 85,
    sortname: "GR",
    country: "Greece",
    status: 0,
    phonecode: 30,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 86,
    sortname: "GL",
    country: "Greenland",
    status: 0,
    phonecode: 299,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 87,
    sortname: "GD",
    country: "Grenada",
    status: 0,
    phonecode: 1473,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 88,
    sortname: "GP",
    country: "Guadeloupe",
    status: 0,
    phonecode: 590,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 89,
    sortname: "GU",
    country: "Guam",
    status: 0,
    phonecode: 1671,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 90,
    sortname: "GT",
    country: "Guatemala",
    status: 0,
    phonecode: 502,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 91,
    sortname: "XU",
    country: "Guernsey and Alderney",
    status: 0,
    phonecode: 44,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 92,
    sortname: "GN",
    country: "Guinea",
    status: 0,
    phonecode: 224,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 93,
    sortname: "GW",
    country: "Guinea-Bissau",
    status: 0,
    phonecode: 245,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 94,
    sortname: "GY",
    country: "Guyana",
    status: 0,
    phonecode: 592,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 95,
    sortname: "HT",
    country: "Haiti",
    status: 0,
    phonecode: 509,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 96,
    sortname: "HM",
    country: "Heard and McDonald Islands",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 97,
    sortname: "HN",
    country: "Honduras",
    status: 0,
    phonecode: 504,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 98,
    sortname: "HK",
    country: "Hong Kong S.A.R.",
    status: 0,
    phonecode: 852,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 99,
    sortname: "HU",
    country: "Hungary",
    status: 0,
    phonecode: 36,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 100,
    sortname: "IS",
    country: "Iceland",
    status: 0,
    phonecode: 354,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 101,
    sortname: "IN",
    country: "India",
    status: 1,
    phonecode: 91,
    currencyName: "INR",
    currencySymbol: "₹",
    offset: "+05:30",
  },
  {
    id: 102,
    sortname: "ID",
    country: "Indonesia",
    status: 0,
    phonecode: 62,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 103,
    sortname: "IR",
    country: "Iran",
    status: 0,
    phonecode: 98,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 104,
    sortname: "IQ",
    country: "Iraq",
    status: 0,
    phonecode: 964,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 105,
    sortname: "IE",
    country: "Ireland",
    status: 0,
    phonecode: 353,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 106,
    sortname: "IL",
    country: "Israel",
    status: 0,
    phonecode: 972,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 107,
    sortname: "IT",
    country: "Italy",
    status: 0,
    phonecode: 39,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 108,
    sortname: "JM",
    country: "Jamaica",
    status: 0,
    phonecode: 1876,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 109,
    sortname: "JP",
    country: "Japan",
    status: 0,
    phonecode: 81,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 110,
    sortname: "XJ",
    country: "Jersey",
    status: 0,
    phonecode: 44,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 111,
    sortname: "JO",
    country: "Jordan",
    status: 0,
    phonecode: 962,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 112,
    sortname: "KZ",
    country: "Kazakhstan",
    status: 0,
    phonecode: 7,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 113,
    sortname: "KE",
    country: "Kenya",
    status: 0,
    phonecode: 254,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 114,
    sortname: "KI",
    country: "Kiribati",
    status: 0,
    phonecode: 686,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 115,
    sortname: "KP",
    country: "Korea North",
    status: 0,
    phonecode: 850,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 116,
    sortname: "KR",
    country: "Korea South",
    status: 0,
    phonecode: 82,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 117,
    sortname: "KW",
    country: "Kuwait",
    status: 0,
    phonecode: 965,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 118,
    sortname: "KG",
    country: "Kyrgyzstan",
    status: 0,
    phonecode: 996,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 119,
    sortname: "LA",
    country: "Laos",
    status: 0,
    phonecode: 856,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 120,
    sortname: "LV",
    country: "Latvia",
    status: 0,
    phonecode: 371,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 121,
    sortname: "LB",
    country: "Lebanon",
    status: 0,
    phonecode: 961,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 122,
    sortname: "LS",
    country: "Lesotho",
    status: 0,
    phonecode: 266,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 123,
    sortname: "LR",
    country: "Liberia",
    status: 0,
    phonecode: 231,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 124,
    sortname: "LY",
    country: "Libya",
    status: 0,
    phonecode: 218,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 125,
    sortname: "LI",
    country: "Liechtenstein",
    status: 0,
    phonecode: 423,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 126,
    sortname: "LT",
    country: "Lithuania",
    status: 0,
    phonecode: 370,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 127,
    sortname: "LU",
    country: "Luxembourg",
    status: 0,
    phonecode: 352,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 128,
    sortname: "MO",
    country: "Macau S.A.R.",
    status: 0,
    phonecode: 853,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 129,
    sortname: "MK",
    country: "Macedonia",
    status: 0,
    phonecode: 389,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 130,
    sortname: "MG",
    country: "Madagascar",
    status: 0,
    phonecode: 261,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 131,
    sortname: "MW",
    country: "Malawi",
    status: 0,
    phonecode: 265,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 132,
    sortname: "MY",
    country: "Malaysia",
    status: 0,
    phonecode: 60,
    currencyName: "MYR",
    currencySymbol: "RM",
    offset: "+08:00",
  },
  {
    id: 133,
    sortname: "MV",
    country: "Maldives",
    status: 0,
    phonecode: 960,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 134,
    sortname: "ML",
    country: "Mali",
    status: 0,
    phonecode: 223,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 135,
    sortname: "MT",
    country: "Malta",
    status: 0,
    phonecode: 356,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 136,
    sortname: "XM",
    country: "Man (Isle of)",
    status: 0,
    phonecode: 44,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 137,
    sortname: "MH",
    country: "Marshall Islands",
    status: 0,
    phonecode: 692,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 138,
    sortname: "MQ",
    country: "Martinique",
    status: 0,
    phonecode: 596,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 139,
    sortname: "MR",
    country: "Mauritania",
    status: 0,
    phonecode: 222,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 140,
    sortname: "MU",
    country: "Mauritius",
    status: 0,
    phonecode: 230,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 141,
    sortname: "YT",
    country: "Mayotte",
    status: 0,
    phonecode: 269,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 142,
    sortname: "MX",
    country: "Mexico",
    status: 0,
    phonecode: 52,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 143,
    sortname: "FM",
    country: "Micronesia",
    status: 0,
    phonecode: 691,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 144,
    sortname: "MD",
    country: "Moldova",
    status: 0,
    phonecode: 373,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 145,
    sortname: "MC",
    country: "Monaco",
    status: 0,
    phonecode: 377,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 146,
    sortname: "MN",
    country: "Mongolia",
    status: 0,
    phonecode: 976,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 147,
    sortname: "MS",
    country: "Montserrat",
    status: 0,
    phonecode: 1664,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 148,
    sortname: "MA",
    country: "Morocco",
    status: 0,
    phonecode: 212,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 149,
    sortname: "MZ",
    country: "Mozambique",
    status: 0,
    phonecode: 258,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 150,
    sortname: "MM",
    country: "Myanmar",
    status: 0,
    phonecode: 95,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 151,
    sortname: "NA",
    country: "Namibia",
    status: 0,
    phonecode: 264,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 152,
    sortname: "NR",
    country: "Nauru",
    status: 0,
    phonecode: 674,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 153,
    sortname: "NP",
    country: "Nepal",
    status: 0,
    phonecode: 977,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 154,
    sortname: "AN",
    country: "Netherlands Antilles",
    status: 0,
    phonecode: 599,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 155,
    sortname: "NL",
    country: "Netherlands The",
    status: 0,
    phonecode: 31,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 156,
    sortname: "NC",
    country: "New Caledonia",
    status: 0,
    phonecode: 687,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 157,
    sortname: "NZ",
    country: "New Zealand",
    status: 0,
    phonecode: 64,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 158,
    sortname: "NI",
    country: "Nicaragua",
    status: 0,
    phonecode: 505,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 159,
    sortname: "NE",
    country: "Niger",
    status: 0,
    phonecode: 227,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 160,
    sortname: "NG",
    country: "Nigeria",
    status: 0,
    phonecode: 234,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 161,
    sortname: "NU",
    country: "Niue",
    status: 0,
    phonecode: 683,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 162,
    sortname: "NF",
    country: "Norfolk Island",
    status: 0,
    phonecode: 672,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 163,
    sortname: "MP",
    country: "Northern Mariana Islands",
    status: 0,
    phonecode: 1670,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 164,
    sortname: "NO",
    country: "Norway",
    status: 0,
    phonecode: 47,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 165,
    sortname: "OM",
    country: "Oman",
    status: 0,
    phonecode: 968,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 166,
    sortname: "PK",
    country: "Pakistan",
    status: 0,
    phonecode: 92,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 167,
    sortname: "PW",
    country: "Palau",
    status: 0,
    phonecode: 680,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 168,
    sortname: "PS",
    country: "Palestinian Territory Occupied",
    status: 0,
    phonecode: 970,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 169,
    sortname: "PA",
    country: "Panama",
    status: 0,
    phonecode: 507,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 170,
    sortname: "PG",
    country: "Papua new Guinea",
    status: 0,
    phonecode: 675,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 171,
    sortname: "PY",
    country: "Paraguay",
    status: 0,
    phonecode: 595,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 172,
    sortname: "PE",
    country: "Peru",
    status: 0,
    phonecode: 51,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 173,
    sortname: "PH",
    country: "Philippines",
    status: 0,
    phonecode: 63,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 174,
    sortname: "PN",
    country: "Pitcairn Island",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 175,
    sortname: "PL",
    country: "Poland",
    status: 0,
    phonecode: 48,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 176,
    sortname: "PT",
    country: "Portugal",
    status: 0,
    phonecode: 351,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 177,
    sortname: "PR",
    country: "Puerto Rico",
    status: 0,
    phonecode: 1787,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 178,
    sortname: "QA",
    country: "Qatar",
    status: 1,
    phonecode: 974,
    currencyName: "QAR",
    currencySymbol: "QAR",
    offset: "+03:00",
  },
  {
    id: 179,
    sortname: "RE",
    country: "Reunion",
    status: 0,
    phonecode: 262,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 180,
    sortname: "RO",
    country: "Romania",
    status: 0,
    phonecode: 40,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 181,
    sortname: "RU",
    country: "Russia",
    status: 0,
    phonecode: 70,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 182,
    sortname: "RW",
    country: "Rwanda",
    status: 0,
    phonecode: 250,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 183,
    sortname: "SH",
    country: "Saint Helena",
    status: 0,
    phonecode: 290,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 184,
    sortname: "KN",
    country: "Saint Kitts And Nevis",
    status: 0,
    phonecode: 1869,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 185,
    sortname: "LC",
    country: "Saint Lucia",
    status: 0,
    phonecode: 1758,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 186,
    sortname: "PM",
    country: "Saint Pierre and Miquelon",
    status: 0,
    phonecode: 508,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 187,
    sortname: "VC",
    country: "Saint Vincent And The Grenadines",
    status: 0,
    phonecode: 1784,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 188,
    sortname: "WS",
    country: "Samoa",
    status: 0,
    phonecode: 684,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 189,
    sortname: "SM",
    country: "San Marino",
    status: 0,
    phonecode: 378,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 190,
    sortname: "ST",
    country: "Sao Tome and Principe",
    status: 0,
    phonecode: 239,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 191,
    sortname: "SA",
    country: "Saudi Arabia",
    status: 0,
    phonecode: 966,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 192,
    sortname: "SN",
    country: "Senegal",
    status: 0,
    phonecode: 221,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 193,
    sortname: "RS",
    country: "Serbia",
    status: 0,
    phonecode: 381,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 194,
    sortname: "SC",
    country: "Seychelles",
    status: 0,
    phonecode: 248,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 195,
    sortname: "SL",
    country: "Sierra Leone",
    status: 0,
    phonecode: 232,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 196,
    sortname: "SG",
    country: "Singapore",
    status: 0,
    phonecode: 65,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 197,
    sortname: "SK",
    country: "Slovakia",
    status: 0,
    phonecode: 421,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 198,
    sortname: "SI",
    country: "Slovenia",
    status: 0,
    phonecode: 386,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 199,
    sortname: "XG",
    country: "Smaller Territories of the UK",
    status: 0,
    phonecode: 44,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 200,
    sortname: "SB",
    country: "Solomon Islands",
    status: 0,
    phonecode: 677,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 201,
    sortname: "SO",
    country: "Somalia",
    status: 0,
    phonecode: 252,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 202,
    sortname: "ZA",
    country: "South Africa",
    status: 0,
    phonecode: 27,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 203,
    sortname: "GS",
    country: "South Georgia",
    status: 0,
    phonecode: 0,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 204,
    sortname: "SS",
    country: "South Sudan",
    status: 0,
    phonecode: 211,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 205,
    sortname: "ES",
    country: "Spain",
    status: 0,
    phonecode: 34,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 206,
    sortname: "LK",
    country: "Sri Lanka",
    status: 0,
    phonecode: 94,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 207,
    sortname: "SD",
    country: "Sudan",
    status: 0,
    phonecode: 249,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 208,
    sortname: "SR",
    country: "Suriname",
    status: 0,
    phonecode: 597,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 209,
    sortname: "SJ",
    country: "Svalbard And Jan Mayen Islands",
    status: 0,
    phonecode: 47,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 210,
    sortname: "SZ",
    country: "Swaziland",
    status: 0,
    phonecode: 268,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 211,
    sortname: "SE",
    country: "Sweden",
    status: 0,
    phonecode: 46,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 213,
    sortname: "SY",
    country: "Syria",
    status: 0,
    phonecode: 963,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 214,
    sortname: "TW",
    country: "Taiwan",
    status: 0,
    phonecode: 886,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 215,
    sortname: "TJ",
    country: "Tajikistan",
    status: 0,
    phonecode: 992,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 216,
    sortname: "TZ",
    country: "Tanzania",
    status: 0,
    phonecode: 255,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 217,
    sortname: "TH",
    country: "Thailand",
    status: 0,
    phonecode: 66,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 218,
    sortname: "TG",
    country: "Togo",
    status: 0,
    phonecode: 228,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 219,
    sortname: "TK",
    country: "Tokelau",
    status: 0,
    phonecode: 690,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 220,
    sortname: "TO",
    country: "Tonga",
    status: 0,
    phonecode: 676,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 221,
    sortname: "TT",
    country: "Trinidad And Tobago",
    status: 0,
    phonecode: 1868,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 222,
    sortname: "TN",
    country: "Tunisia",
    status: 0,
    phonecode: 216,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 223,
    sortname: "TR",
    country: "Turkey",
    status: 0,
    phonecode: 90,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 224,
    sortname: "TM",
    country: "Turkmenistan",
    status: 0,
    phonecode: 7370,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 225,
    sortname: "TC",
    country: "Turks And Caicos Islands",
    status: 0,
    phonecode: 1649,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 226,
    sortname: "TV",
    country: "Tuvalu",
    status: 0,
    phonecode: 688,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 227,
    sortname: "UG",
    country: "Uganda",
    status: 0,
    phonecode: 256,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 228,
    sortname: "UA",
    country: "Ukraine",
    status: 0,
    phonecode: 380,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 229,
    sortname: "AE",
    country: "United Arab Emirates",
    status: 0,
    phonecode: 971,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 230,
    sortname: "GB",
    country: "United Kingdom",
    status: 0,
    phonecode: 44,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 231,
    sortname: "US",
    country: "United States",
    status: 0,
    phonecode: 1,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 232,
    sortname: "UM",
    country: "United States Minor Outlying Islands",
    status: 0,
    phonecode: 1,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 233,
    sortname: "UY",
    country: "Uruguay",
    status: 0,
    phonecode: 598,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 234,
    sortname: "UZ",
    country: "Uzbekistan",
    status: 0,
    phonecode: 998,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 235,
    sortname: "VU",
    country: "Vanuatu",
    status: 0,
    phonecode: 678,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 236,
    sortname: "VA",
    country: "Vatican City State (Holy See)",
    status: 0,
    phonecode: 39,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 237,
    sortname: "VE",
    country: "Venezuela",
    status: 0,
    phonecode: 58,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 238,
    sortname: "VN",
    country: "Vietnam",
    status: 0,
    phonecode: 84,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 239,
    sortname: "VG",
    country: "Virgin Islands (British)",
    status: 0,
    phonecode: 1284,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 240,
    sortname: "VI",
    country: "Virgin Islands (US)",
    status: 0,
    phonecode: 1340,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 241,
    sortname: "WF",
    country: "Wallis And Futuna Islands",
    status: 0,
    phonecode: 681,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 242,
    sortname: "EH",
    country: "Western Sahara",
    status: 0,
    phonecode: 212,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 243,
    sortname: "YE",
    country: "Yemen",
    status: 0,
    phonecode: 967,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 244,
    sortname: "YU",
    country: "Yugoslavia",
    status: 0,
    phonecode: 38,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 245,
    sortname: "ZM",
    country: "Zambia",
    status: 0,
    phonecode: 260,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
  {
    id: 246,
    sortname: "ZW",
    country: "Zimbabwe",
    status: 0,
    phonecode: 263,
    currencyName: null,
    currencySymbol: null,
    offset: "+00:00",
  },
];
